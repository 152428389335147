import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

let time = new Date().toLocaleTimeString("en-US", {
  hour12: false
});

ReactDOM.render(<App time={time} />, document.getElementById("root"));

console.log(time);
